@tailwind base;
@tailwind components;
@tailwind utilities;

// Import font
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

// Ng select styles
@import "assets/styles/select.scss";

// NGX Toastr styles
@import "ngx-toastr/toastr";

@import "assets/styles/inputs.scss";

// app global style
body {
    font-family: Poppins, sans-serif;
    @apply text-sm text-iobase-blue bg-iobase-grey-lighter block pb-14 h-screen;

    app-root {
        @apply h-full;
    }

    &:has(app-bad-network) {
        @apply pt-8;
    }
}

button {
    -webkit-tap-highlight-color: transparent;

    @apply px-5 text-center;

    &.switch {
        @apply px-2;
    }

    &.validation {
        @apply text-base font-semibold outline outline-2 rounded-lg shadow-md outline-iobase-orange h-15 shadow-iobase-orange_light;

        &.fill {
            @apply bg-iobase-orange text-white;
        }

        &.white {
            @apply bg-white;
        }

        &.pill {
            @apply rounded-full h-12;
        }

        &:disabled {
            @apply bg-iobase-grey-light text-iobase-grey-base outline-none shadow-none;
        }
    }

    &.sm {
        @apply h-8 px-4 font-medium bg-white rounded-full;

        &.fill {
            @apply bg-iobase-orange_light font-semibold;
        }

        &.dark {
            @apply bg-[#f8f8f8];
        }

        &.bordered {
            @apply outline outline-1 outline-iobase-grey-light;
        }

        &.shadowed {
            @apply shadow-md;
        }

        &:has(img:only-child) {
            @apply flex justify-center items-center;
        }

        &:disabled {
            @apply bg-white outline outline-1 border-iobase-grey-light shadow-none text-iobase-grey-base;
        }
    }

    &.float {
        float: inline-end;
    }

    &:active:not(:disabled) {
        animation: basic-shadow 100ms ease 0s 1 normal forwards;
    }
}

.item-click {
    &:active:not(:disabled) {
        animation: basic-shadow 100ms ease 0s 1 normal forwards;
    }
}

.bg-gradient {
    background: linear-gradient(180deg,
            rgba(50, 48, 102, 0) 0%,
            rgba(50, 48, 102, 0.11) 52%,
            rgba(50, 48, 102, 0.35) 100%);
}

.bg-gradient-light {
    background: linear-gradient(180deg,
            rgba(50, 48, 102, 0) 0%,
            rgba(50, 48, 102, 0.05) 52%,
            rgba(50, 48, 102, 0.18) 100%);
}

@keyframes basic-shadow {
    from {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }

    to {
        box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.25);
    }
}

zxing-scanner {
    video {
        height: 100% !important;
    }
}