@import "~@ng-select/ng-select/themes/default.theme.css";

// ng-select.ng-select-multiple.hide-values .ng-select-container .ng-value-container .ng-value {
//     margin-right: 0;
// }

.ng-select {
    .ng-select-container {
        @apply border border-iobase-grey-base rounded-md  h-10;
    }

    &.ng-select.ng-select-opened {
        > .ng-select-container {
            @apply border-2 border-iobase-orange border-b-2;
        }
        > .ng-dropdown-panel.ng-select-bottom {
            @apply border border-iobase-grey-base border-t-0;
        }
    }

    &.ng-select-single .ng-select-container {
        @apply h-10;
    }

    &.ng-select-multiple.hide-values .ng-select-container .ng-value-container .ng-value {
        @apply mr-0;
    }

    input {
        height: unset;
    }

    &.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
        top: unset;
    }
}
