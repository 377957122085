input {
    @apply h-10 outline outline-iobase-grey-base outline-1 bg-white rounded-md p-2;

    &:focus,
    &:focus-visible {
        @apply outline-iobase-orange outline-2 outline;
    }

    &:disabled {
        @apply bg-iobase-grey-light;
    }

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type="number"] {
        -moz-appearance: textfield;
        // wierd hack for firefox, who takes more place because of arrows (even in textfield mode), and push affixes outside
        // any width fix it, setting to 100% to be sure
        width: 100%;
    }
}

.input-group {
    @apply h-10 flex outline outline-iobase-grey-base outline-1 bg-white rounded-md p-2 gap-1 items-center;

    &:has(input:focus-visible, input:focus) {
        @apply outline-iobase-orange outline-2;
    }

    &.input-group-error {
        @apply outline-red-600 outline-2;
    }

    input {
        @apply outline-0 p-0 h-full grow outline-none;
    }
}

textarea {
    &:focus,
    &:focus-visible {
        @apply outline-iobase-orange outline-2;
    }
}
